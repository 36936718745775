import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';

import { AuthService } from '../../material-component/authorization/auth/services/auth.service';

export interface Menu {
  name: string;
  type: string;
}

export interface MenuItem extends Menu {
  state: string;
  icon: string;
}

const COVIAR_MENUITEMS = [
  {
    state: 'proyecto',
    type: 'link',
    name: 'Proyectos',
    icon: 'dashboard'
  },
  {
    state: 'inf-tec-estado',
    name: 'Dashboard',
    type: 'link',
    icon: 'verified'
  },
  {
    state: 'usuarios',
    type: 'link',
    name: 'Usuarios',
    icon: 'supervised_user_circle'
  },
  {
    state: 'presupuesto-anual',
    type: 'link',
    name: 'Presupuesto Anual',
    icon: 'attach_money'
  },
  {
    state: 'flujo-mensual',
    type: 'link',
    name: 'Flujo Mensual',
    icon: 'local_atm'
  },
  {
    name: 'Objetivos y Acciones',
    type: 'header',
  },
  {
    state: 'acciones-estrategicas',
    type: 'link',
    name: 'Acciones Estratégicas',
    icon: 'assignment_turned_in'
  },
  {
    state: 'objetivos-estrategicos',
    type: 'link',
    name: 'Objetivos Estratégicos',
    icon: 'my_location'
  },
  {
    state: 'indicador-objetivo',
    type: 'link',
    name: 'Indicadores de Objetivo',
    icon: 'check'
  },
  {
    name: 'ABM',
    type: 'header',
  },
  {
    state: 'zonas',
    type: 'link',
    name: 'Zonas',
    icon: 'map'
  },
  {
    state: 'unidad-participante',
    type: 'link',
    name: 'Unidad Participante',
    icon: 'assignment_ind'
  },
  {
    state: 'unidad-ejecutora',
    type: 'link',
    name: 'Unidad Ejecutora',
    icon: 'bar_chart'
  },
  {
    state: 'periodo-fiscal',
    type: 'link',
    name: 'Período Fiscal',
    icon: 'date_range'
  },
  {
    state: 'tipo-de-gastos',
    type: 'link',
    name: 'Tipo de Gastos',
    icon: 'monetization_on'
  },
  {
    state: 'indicador-agregado',
    type: 'link',
    name: 'Indicadores Agregados',
    icon: 'drag_indicator'
  },
  {
    state: 'indicador-gestion',
    type: 'link',
    name: 'Indicadores de Gestión',
    icon: 'trending_up'
  },
];

const UE_MENUITEMS = [
  /*{
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'av_timer'
  },*/
  {
    state: 'proyecto',
    type: 'link',
    name: 'Proyectos',
    icon: 'dashboard'
  },
];

@Injectable()
export class MenuItems {

  constructor(private authService: AuthService) {}

  getMenuitem(): Menu[] {
    let menu_items: any[] = null;
    const user: any = jwtDecode(localStorage.getItem('currentUser'));

    if (user.tipoUsuario === 'UsuarioUE') {
      menu_items = UE_MENUITEMS;
    } else if (user.tipoUsuario === 'UsuarioCoviar') {
      menu_items = COVIAR_MENUITEMS;
    }

    return menu_items;
  }
}
